import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="flex flex-col justify-center items-center min-h-screen">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/" className="btn btn--primary btn--lg mt-8">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
